import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const maskToCurrency = ({ nextState }) => {
  const { value } = nextState || {};

  let amountFormatted = value?.replace?.(/\D/g, "");
  amountFormatted = amountFormatted?.replace?.(/^0+/g, "");

  if (amountFormatted?.length === 2) {
    return {
      ...nextState,
      value: `Rs$ ${amountFormatted}`,
      selection: {
        start: amountFormatted.length + 3,
        end: amountFormatted.length + 3,
      },
    };
  }

  const amountFormattedWithComma = amountFormatted?.replace?.(
    /(?=\d{2})(\d{2})$/,
    ",$1"
  );
  const amountFormattedWithDot = amountFormattedWithComma?.replace?.(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1."
  );

  if (amountFormattedWithDot) {
    return {
      ...nextState,
      value: `R$ ${amountFormattedWithDot}`,
      selection: {
        start: amountFormattedWithDot.length + 3,
        end: amountFormattedWithDot.length + 3,
      },
    };
  }

  return nextState;
};

export const formatPrice = (number, options = {}) => {
  const { moneySign = true } = options as any;

  if (typeof number === "string") {
    number = Number(number);
  }

  let res;

  const config = moneySign
    ? { style: "currency", currency: "BRL" }
    : { minimumFractionDigits: 2 };

  moneySign
    ? (res = number?.toLocaleString("pt-BR", config))
    : (res = number?.toLocaleString("pt-BR", config));

  const needComma = (number) => number <= 1000;
  if (needComma(number)) {
    res = res.toString().replace(".", ",");
  }

  return res;
};
